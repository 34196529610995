import { Alert, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, FormEvent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import useNswagClient from './../../../../hooks/api/useNswagClient';
import { GetItemsByStoresRequest, Item, PrimaryCategory2, StockOnHandReportRequest, Store, SuccessResponse_1OfList_1OfItem } from './../../../../app/services/api/generated';
import { useTranslation } from 'react-i18next';
import { formatDateForReport } from './../../../../utils';
import { UserContext } from './../../../../components/shared/useUser';
import { InfoCircle } from './../../../../assets';
import { theme } from './../../../../theme';
import useLogError from './../../../../hooks/useLogError';
import axios from 'axios';

type IProps = {
  onClose?: () => void;
}

const StockOnHandReportForm = (props: IProps) => {
  const { selectedStore, user, hasPermissionTo } = useContext(UserContext);
  const [allCategories, setAllCategories] = useState<PrimaryCategory2[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<PrimaryCategory2[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [date, setDate] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState<boolean>(false);
  const [hasRecentActivity, setHasRecentActivity] = useState<boolean>(true);
  const [onlyMadeInStore, setOnlyMadeInStore] = useState<boolean>(false);
  const [canSeeMadeInStore, setCanSeeMadeInStore] = useState<boolean>(false);
  const [checkedAllStores, setCheckedAllStores] = useState<boolean>(false);
  const [checkedAllItems, setCheckedAllItems] = useState<boolean>(true);
  const [storeItemsMap, setStoreItemsMap] = useState<Map<string, Item[]>>(new Map<string, Item[]>());
  const { getPrimaryCategoriesForStore, runStockOnHandReport, getItemsByStoreNumbers } = useNswagClient();
  const { logError } = useLogError();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (selectedStore?.storeNumber) {
      loadCategories(selectedStore.storeNumber);
      setCanSeeMadeInStore(hasPermissionTo(['MadeInStoreRead']));
    }
  }, [selectedStore]);

  const loadCategories = (storeNumber: string) => {
    getPrimaryCategoriesForStore(storeNumber).then((res) => {
      if (res.data) {
        setAllCategories(res.data);
        setSelectedCategories(res.data);
      }
    }).catch((error) => {
      logError(error);
    });
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setSelectedStores([]);

    setCheckedAllStores(checked);

    if (checked) fetchDataWithTimeout(user?.stores ?? []);
  };

  const handleSelectAllCategoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setSelectedCategories(allCategories);
    } else {
      setSelectedCategories([]);
    }
    filterItemNumbersWithSelectedStores(selectedStores);
  };

  const handleSelectAllItemsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setSelectedItems([]);

    setCheckedAllItems(checked);
  };

  const fetchDataWithTimeout = async (stores: Store[], storeNumber?: string) => {
    setErrorMessage('');

    const request: GetItemsByStoresRequest = {
      stores: storeNumber ? [storeNumber] : stores.map(x => x.storeNumber!),
    };
    
    const timeoutDuration = 60000; //60 seconds timeout;
    const cancelSource = axios.CancelToken.source();
    
    try {
      const fetchDataPromise = getItemsByStoreNumbers(request, cancelSource.token);
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Request timed out'));
        }, timeoutDuration);
      });

      const res = await Promise.race([fetchDataPromise, timeoutPromise]) as SuccessResponse_1OfList_1OfItem;

      handleNewItems(res.data!, storeNumber);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === 'Request timed out') {
        cancelSource.cancel();
        setErrorMessage(t('reporting.request-timeout'));
      }
      logError(error);
    }
  };

  const handleNewItems = (items: Item[], storeNumber?: string) => {
    if (storeNumber) {
      const newMap = new Map<string, Item[]>(storeItemsMap);
      newMap.set(storeNumber, items);
      setStoreItemsMap(newMap);
      setAllItems(unionArrays(allItems, items));
    } else {
      const newMap = groupByStoreNumber(items);
      setStoreItemsMap(newMap);
      setAllItems(unionArrays([], items));
    }
    filterItemNumbersWithSelectedCategories(selectedCategories);
  };

  const groupByStoreNumber = (data: Item[]): Map<string, Item[]> => {
    const map = data.reduce((x, y) => {
      if(!x.has(y.storeNumber)) {
        x.set(y.storeNumber, []);
      }
      x.get(y.storeNumber).push(y);
      return x;
    }, new Map());
    return map;
  };

  const filterItemNumbersWithSelectedCategories = (categories: PrimaryCategory2[]) => {
    if(allCategories.length !== categories.length && categories.length > 0) {
      setAllItems((prev) => {
        return prev.filter(x => categories.some(y => y.primaryCategoryName === x.primaryCategory));
      });
      setSelectedItems((prev) => {
        return prev.filter(x => categories.some(y => y.primaryCategoryName === x.primaryCategory));
      });
    }
  };

  const getMaxDate = () => {
    const today = new Date().toISOString().split('T')[0];
    return today;
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const handleCategorySelectChange = (event: SyntheticEvent, value: PrimaryCategory2[]) => {
    setSelectedCategories(value);

    filterItemNumbersWithSelectedStores(selectedStores);
    
    if (value.length > 0) {
      filterItemNumbersWithSelectedCategories(value);
    }
  };

  const handleItemSelectChange = (event: SyntheticEvent, value: Item[]) => {
    setSelectedItems(value);
  };

  const handleSelectChange = (event: SyntheticEvent, value: Store[]) => {
    setSelectedStores((prev) => {
      const addedStores = value.filter(item => !prev.includes(item));
      const removedStores = prev.filter(item => !value.includes(item));

      if (addedStores.length > 0) {
        onStoreSelected(addedStores[0].storeNumber ?? '');
      }
      else if (removedStores.length > 0) {
        onStoreDeselected(value);
      }
    
      return [...value];
    });
  };

  const onStoreSelected = (storeNumber: string) => {
    if(storeItemsMap.has(storeNumber)) { // use cached values
      setAllItems(unionArrays(allItems, storeItemsMap.get(storeNumber) ?? []));
      filterItemNumbersWithSelectedCategories(selectedCategories);
      return;
    }
    fetchDataWithTimeout([], storeNumber);
  };

  const onStoreDeselected = (stores: Store[]) => {
    setSelectedItems([]);
    setAllItems([]);
    filterItemNumbersWithSelectedStores(stores);
    filterItemNumbersWithSelectedCategories(selectedCategories);
  };

  const filterItemNumbersWithSelectedStores = (stores: Store[]) => {
    setAllItems((prev) => {
      let items = [...prev];
      for(const storeNumber of stores.map(x => x.storeNumber)) {
        items = unionArrays(items, storeItemsMap.get(storeNumber!) ?? []);
      }
      return items;
    });
  };

  const unionArrays = (arr1: Item[], arr2: Item[]): Item[] => {
    const uniqueItems = [...arr1];
    const seenItemNumbers = new Set([...arr1.map(x => x.itemNumber)]);
    arr2.forEach(item => {
      if(!seenItemNumbers.has(item.itemNumber)) {
        uniqueItems.push(item);
        seenItemNumbers.add(item.itemNumber);
      }
    });
    return uniqueItems;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsGenerating(true);
    setErrorMessage('');
    setSuccessMessage('');

    const reportDate = new Date(new Date(date).setHours(0, 0, 0, 0));
    const selectedAllStores = checkedAllStores || (selectedStores.length > 0 && selectedStores.length === user?.stores?.length);

    const request: StockOnHandReportRequest = {
      reportOnAllAvailableStores: selectedAllStores,
      primaryCategoryIds: selectedCategories.length === allCategories.length ? [] : selectedCategories.map(x => x.id as number),
      itemNumbers: checkedAllItems ? [] : selectedItems.map(x => x.itemNumber!),
      stores: selectedAllStores ? undefined : selectedStores.map(x => x.storeNumber as string),
      date: formatDateForReport(reportDate),
      hasRecentActivity: hasRecentActivity,
      onlyMadeInStore: onlyMadeInStore,
    };

    runStockOnHandReport(request).then(() => {
      setSuccessMessage(t('reporting.generic.post-generation-message'));
      setIsRequestSubmitted(true);
    }).catch((error) => {
      if (typeof(error) === 'string') {
        setErrorMessage(error);
      }
      console.log(error);
    }).finally(() => {
      setIsGenerating(false);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container
        spacing={10}
        sx={{ pt: '10px' }}>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={user?.stores ?? []}
              value={selectedStores}
              onChange={handleSelectChange}
              disabled={checkedAllStores}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedStores.length === 0 && !checkedAllStores}
                  label={t('reporting.select-stores')}
                  placeholder={t('reporting.select-stores')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox
                checked={checkedAllStores}
                onChange={handleSelectAllChange} />}
              label={t('reporting.all-stores')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={allCategories}
              value={selectedCategories}
              onChange={handleCategorySelectChange}
              getOptionKey={(option) => option.id ?? ''}
              getOptionLabel={(option) => option.primaryCategoryName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedCategories.length === 0}
                  label={t('reporting.select-categories')}
                  placeholder={t('reporting.select-categories')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox checked={allCategories.length === selectedCategories.length}
                onChange={handleSelectAllCategoriesChange} />}
              label={t('reporting.all-categories')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              limitTags={2}
              options={allItems}
              value={selectedItems}
              disabled={checkedAllItems}
              onChange={handleItemSelectChange}
              getOptionKey={(option) => option.id ?? ''}
              getOptionLabel={(option) => `${option.itemNumber}-${option.description}`}
              renderInput={(params) => (
                <TextField {...params}
                  required={selectedItems.length === 0 && !checkedAllItems}
                  label={t('reporting.sku-search')}
                  placeholder={t('reporting.select-item-numbers')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={<Checkbox checked={checkedAllItems}
                onChange={handleSelectAllItemsChange} />}
              label={t('reporting.all-sku')}
            />
          </FormGroup>
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <TextField
            label={t('date')}
            type="date"
            required
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { max: getMaxDate() } }}
            fullWidth
          />
        </Grid>
        <Grid item
          sm={6}
          xs={12}>
          <FormGroup>
            <FormControlLabel
              sx={{ alignItems: 'center' }}
              control={<Checkbox
                checked={hasRecentActivity}
                onChange={(event) => setHasRecentActivity(event.target.checked)} />}
              label={
                <Typography sx={{ display: 'flex' }}>
                  {t('reporting.generic.only-recent-activity')} 
                  <Tooltip title={t('reporting.generic.tooltip-only-recent-activity')}>
                    <SvgIcon sx={{ width: '20px', ml: '5px' }}>
                      <InfoCircle color={theme.palette.custom.blue[500]} />
                    </SvgIcon>
                  </Tooltip>
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        {
          canSeeMadeInStore && 
          <Grid item
            sm={6}
            xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox
                  checked={onlyMadeInStore}
                  onChange={(event) => setOnlyMadeInStore(event.target.checked)} />}
                label={
                  <Typography sx={{ display: 'flex' }}>
                    {t('reporting.generic.only-made-in-store')} 
                    <Tooltip title={t('reporting.generic.tooltip-only-made-in-store')}>
                      <SvgIcon sx={{ width: '20px', ml: '5px' }}>
                        <InfoCircle color={theme.palette.custom.blue[500]} />
                      </SvgIcon>
                    </Tooltip>
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        }
        {
          errorMessage && 
          <Grid item
            xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        }
        {
          successMessage && 
          <Grid item
            xs={12}>
            <Alert severity="success">{successMessage}</Alert>
          </Grid>
        }
        <Grid item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'right', columnGap: '10px' }}>
          <Button size='lg'
            variant='secondary' 
            onClick={props.onClose}>{t('cancel')}</Button>
          <Button size='lg'
            disabled={isGenerating || isRequestSubmitted}
            type='submit'>{t('reporting.generate')}</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default StockOnHandReportForm;