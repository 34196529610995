export function isString(x: unknown): x is string {
  return typeof x === 'string' || x instanceof String;
}

export function isNumber(x: unknown): x is number {
  return typeof x === 'number' || x instanceof Number;
}
export const enum Reason {
    SHORTAGE = 1,
    DAMAGE = 2,
    EXPIRED_STALE_PRODUCT = 3,
    OUT_OF_TEMPERATURE = 4, // no longer valid but old data may still have this value
    OVERAGE = 5,
    NO_PRODUCT_IN_DELIVERY = 11,
    EXCESS_INVOICE_ADJUSTMENT = 12,
    DAMAGED_IN_TRANSIT = 13,
    OVER_TEMPERATURE_DELIVERY = 14,
    SHORT_SHELF_LIFE = 16,
    EXCESS_RETURN_DRIVER = 17,
    RETURN_REQUESTED_BY_RESTAURANT = 20
}

export const creditRequestReasons = [
  Reason.SHORTAGE,
  Reason.DAMAGE,
  Reason.EXPIRED_STALE_PRODUCT,
  Reason.OUT_OF_TEMPERATURE,
  Reason.OVERAGE,
];

export const creditRequestReasonsPoland = [
  Reason.NO_PRODUCT_IN_DELIVERY,
  Reason.EXCESS_INVOICE_ADJUSTMENT,
  Reason.DAMAGED_IN_TRANSIT,
  Reason.OVER_TEMPERATURE_DELIVERY,
  Reason.SHORT_SHELF_LIFE,
  Reason.EXCESS_RETURN_DRIVER,
  Reason.RETURN_REQUESTED_BY_RESTAURANT,
];

export const creditRequestReasonsTranslationMap = {
  [Reason.SHORTAGE]: 'creditRequestReasons.shortage',
  [Reason.DAMAGE]: 'creditRequestReasons.damage',
  [Reason.EXPIRED_STALE_PRODUCT]: 'creditRequestReasons.expired_stale_product',
  [Reason.OUT_OF_TEMPERATURE]: 'creditRequestReasons.out_of_temperature',
  [Reason.OVERAGE]: 'creditRequestReasons.overage',
  [Reason.NO_PRODUCT_IN_DELIVERY]: 'creditRequestReasons.no_product_in_delivery',
  [Reason.EXCESS_INVOICE_ADJUSTMENT]: 'creditRequestReasons.excess_invoice_adjustment',
  [Reason.DAMAGED_IN_TRANSIT]: 'creditRequestReasons.damaged_in_transit',
  [Reason.OVER_TEMPERATURE_DELIVERY]: 'creditRequestReasons.over_temperature_delivery',
  [Reason.SHORT_SHELF_LIFE]: 'creditRequestReasons.short_shelf_life',
  [Reason.EXCESS_RETURN_DRIVER]: 'creditRequestReasons.excess_return_driver',
  [Reason.RETURN_REQUESTED_BY_RESTAURANT]: 'creditRequestReasons.return_requested_by_restaurant',
};

export function parseReason(value: Reason | number | string): Reason {
  if (isString(value)) {
    value = parseInt(value) as Reason;
  }

  if (isNumber(value)) {
    value = value as Reason;
  }

  return value;
}