export enum OrderType {
  DirectStoreDelivery = 12,
}

export enum OrdersType {
  Fresh = 'Fresh',
  Ambient = 'Ambient',
  AmbientAutoship = 'Ambient (autoship)',
  HVI = 'HVI',
  FreshAutoship = 'Fresh (autoship)',
  StoreDevelopment = 'Store Development',
  HVIAutoship = 'HVI (autoship)',
  All = 'All'
}

export enum CatalogueBasedOrderType {
  Daily_Morning_Fresh_and_Dairy_Order = 0,
  Weekly_Afternoon_Standard_Ambient_Order = 1,
  Weekly_Afternoon_Auto_Ship_Ambient_Order = 2,
  Daily_Morning_Auto_Ship_Fresh_Order = 4,
  Daily_Morning_High_Volume_Ambient_Orders = 3,
  Ambient_ARP_Bulk = 5,
  Ambient_Direct_Shipment = 6,
  Ambient_Promotional = 7,
  Store_Development = 9,
  Daily_Morning_Auto_Ship_High_Volume_Ambient_Orders = 10,
  Store_Development_Auto_Ship = 11,
  Direct_Store_Delivery = 12,
  Reefer = 13,
  Reefer_Direct_Shipment = 14,
  Reefer_Auto_Ship = 15,
  Store_Development_Direct_Shipment = 16,
  Prepared_Food = 17,
  Prepared_Food_Auto_Ship = 18,
  Hazmat = 19,
  Hazmat_Auto_Ship = 20,
  Direct_Shipment_Autoship = 23,
  Other = 24,
  Direct_Shipment_Promotional = 25,
  Direct_Store_Delivery_Auto_Ship = 26,
  Ambient2 = 27,
  Ambient2_Auto_Ship = 28,
  Credit_Request = 100,
  Frozen = 101,
  Frozen_Auto_ship = 102,
}

export const isAutoShipOrder = (orderType: number) => {
  const autoShipOrderTypes = new Set([
    CatalogueBasedOrderType.Ambient2_Auto_Ship,
    CatalogueBasedOrderType.Frozen_Auto_ship,
    CatalogueBasedOrderType.Hazmat_Auto_Ship,
    CatalogueBasedOrderType.Reefer_Auto_Ship,
    CatalogueBasedOrderType.Daily_Morning_Auto_Ship_Fresh_Order,
    CatalogueBasedOrderType.Daily_Morning_Auto_Ship_High_Volume_Ambient_Orders,
    CatalogueBasedOrderType.Prepared_Food_Auto_Ship,
    CatalogueBasedOrderType.Direct_Shipment_Autoship,
    CatalogueBasedOrderType.Weekly_Afternoon_Auto_Ship_Ambient_Order,
    CatalogueBasedOrderType.Store_Development_Auto_Ship,
    CatalogueBasedOrderType.Direct_Store_Delivery_Auto_Ship,
  ]);

  return autoShipOrderTypes.has(orderType);
};

export const OrderTypeTranslationMap = {
  [OrdersType.Fresh]: 'ordersType.fresh',
  [OrdersType.Ambient]: 'ordersType.ambient',
  [OrdersType.AmbientAutoship]: 'ordersType.ambient_autoship',
  [OrdersType.HVI]: 'ordersType.hvi',
  [OrdersType.FreshAutoship]: 'ordersType.fresh_autoship',
  [OrdersType.StoreDevelopment]: 'ordersType.store_development',
  [OrdersType.HVIAutoship]: 'ordersType.hvi_autoship',
  [OrdersType.All]: 'ordersType.all',
};

export function isOrderType(value: string | null | undefined): value is OrdersType {
  return value != null && Object.values(OrdersType).includes(value as OrdersType);
}